<template>
  <div class="big-box">
    <el-form label-position="left" :inline="true" class="demo-form-inline">
      <el-form-item label="大区：">
        <el-select v-model="department_id" placeholder="--请选择--">
          <el-option v-for="(item, index) in regionList" :key="index" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店名称：">
        <el-input v-model="store_name" placeholder="请输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input
          v-model="phone"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="use_status" placeholder="全部">
          <el-option label="正常" :value="0"></el-option>
          <el-option label="失效" :value="1"></el-option>
          <el-option label="禁用" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search('clear')">查询</el-button>
        <el-button @click="clearModuleCode">重置</el-button>
      </el-form-item>
    </el-form>
    <div>
      <template>
        <el-table :data="tableData" tooltip-effect="dark" style="width: 90%; margin-left: 40px">

          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="大区" min-width="150">
          </el-table-column>

          <el-table-column prop="store_name" align="center" label="门店名称" min-width="210">
          </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号" min-width="90">
          </el-table-column>
          <el-table-column prop="available_score" align="center" min-width="100" label="可用积分">
          </el-table-column>
          <el-table-column prop="cumulative_score" align="center" min-width="100" label="累计积分">
          </el-table-column>
          <el-table-column prop="invitation_number" align="center" min-width="100" label="激励用户数">
          </el-table-column>
          <el-table-column prop="use_status" align="center" min-width="80" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.use_status == 0" :style="{color: 'green'}">正常</span>
              <span v-if="scope.row.use_status == 1" :style="{color: 'red'}">失效</span>
              <span v-if="scope.row.use_status == 2" :style="{color: 'red'}">禁用</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="updated_at" align="center" min-width="150" label="最近登录时间">
          </el-table-column> -->
          <el-table-column prop="date" align="center" min-width="150" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="checkDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-left: 50px;margin-top: 10px" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page.sync="page" :page-size="10"
          layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
    </div>
    <MemberDetail ref="MemberDetail"></MemberDetail>

  </div>
</template>
<script>
import { getsalesclerklist, department_list } from "@/api/data.js";
import MemberDetail from "@/components/MemberDetail.vue";
export default {
  components: { MemberDetail },
  data() {
    return {
      page: 1,
      total: 0,
      dialogPvVisible: false,
      tableData: [{}],
      department_id: '',
      store_name: '',
      phone: '',
      use_status: '',
      regionList: [],
      roles: ['admin', 'user'],
    };
  },
  created() {
  },
  watch: {
  },
  mounted() {
    this.search('clear')
    this.get_department_list()
  },
  methods: {
    get_department_list() {
      department_list({}).then(({ data: res }) => {
        if (res.code === 200) {
          this.regionList = res.data
        } else {
          this.$message.error(res.message);
        }
      });
    },
    checkDetail(item) {
      this.$refs.MemberDetail.dialogPvVisible = true
      this.$refs.MemberDetail.activeIndex = '1'
      this.$refs.MemberDetail.selectItem = item
      this.$refs.MemberDetail.search('init')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.search()
    },
    search(type) {
      if (type == 'clear') {
        this.page = 1
      }
      getsalesclerklist(
        `?page=${this.page}&limit=10&department_id=${this.department_id}&store_name=${this.store_name}&use_status=${this.use_status}&phone=${this.phone}`
      ).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        } else {
          this.$message.error(res.message);
        }
      });
    },
    clearModuleCode() {
      this.department_id = ''
      this.store_name = ''
      this.use_status = ''
      this.phone = ''
      this.page = 1
      this.search('clear')
    },
  },
};
</script>
<style lang="less" scoped>
.text-content {
  display: inline-block;
  width: 260px;
  padding-left: 20px;
}

.demo-form-inline {
  margin-left: 50px;
}

.head-box {
  height: 40px;
  margin: 20px 0 10px 40px;
}

.add-image {
  text-align: center;
  color: #3ea8d6;
}

.demo-form-inline {
  margin-top: 22px;
  // padding-left: 50px;
}

.input-box {
  width: 120px;
  margin-right: 10px;
}

::v-deep .el-input {
  width: 260px;
}
</style>

<template>
  <div class="big-box">
    <el-dialog :visible.sync="dialogPvVisible" width="1000px" title="详情">
      <el-descriptions column="2">
        <el-descriptions-item label="手机号">{{ selectItem.phone }}</el-descriptions-item>
        <el-descriptions-item label="OPENID">{{ selectItem.wx_openid }}</el-descriptions-item>
        <el-descriptions-item label="最近登录时间">{{ selectItem.last_login_at }}</el-descriptions-item>
        <el-descriptions-item label="当前积分">{{ selectItem.available_score }}</el-descriptions-item>
        <el-descriptions-item label="累计积分">{{ selectItem.cumulative_score }}</el-descriptions-item>
      </el-descriptions>
      <!-- <el-form label-width="150px" :inline="true">
        <el-form-item label="店员昵称:">
          <div class="text-content">{{orderDetail.goods_name}}</div>
        </el-form-item>
        <el-form-item label="OPENID:">
          <div class="text-content">{{orderDetail.goods_type_cn}}</div>
        </el-form-item>
        <el-form-item label="最近登录时间:">
          <div class="text-content">{{orderDetail.user_name}}</div>
        </el-form-item>
        <el-form-item label="当前积分:">
          <div class="text-content">{{orderDetail.created_at}}</div>
        </el-form-item>
        <el-form-item label="累计积分:">
          <div style="width: 700px" class="text-content">{{orderDetail.location}}{{orderDetail.address}}</div>
        </el-form-item>
      </el-form> -->
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">积分获取记录</el-menu-item>
        <el-menu-item index="2">积分消耗记录</el-menu-item>
        <el-menu-item index="3">邀请记录</el-menu-item>
      </el-menu>

      <template v-if="activeIndex == 1">
        <el-table :data="tableData" tooltip-effect="dark">

          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="积分类型" min-width="150">
          </el-table-column>

          <el-table-column prop="score" align="center" label="获得积分" min-width="210">
          </el-table-column>
          <el-table-column prop="created_at" align="center" label="积分时间" min-width="90">
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 10px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="page" :page-size="10" layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
      <template v-if="activeIndex == 2">
        <el-table :data="tableData" tooltip-effect="dark">

          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="积分类型" min-width="150">
          </el-table-column>

          <el-table-column prop="score" align="center" label="积分消耗" min-width="210">
          </el-table-column>
          <el-table-column prop="created_at" align="center" label="消耗时间" min-width="90">
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 10px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="page" :page-size="10" layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
      <template v-if="activeIndex == 3">
        <el-table :data="tableData" tooltip-effect="dark">

          <el-table-column prop="id" align="center" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="member_user_info.nickName" align="center" label="用户昵称" min-width="150">
          </el-table-column>

          <el-table-column prop="total_score" align="center" label="店员获得积分数" min-width="210">
          </el-table-column>
          <el-table-column prop="created_at" align="center" label="邀请时间" min-width="90">
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top: 10px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="page" :page-size="10" layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { get_invitation_list, get_integral_list } from "@/api/data.js";
export default {
  data() {
    return {
      activeIndex: '1',
      dialogPvVisible: false,
      selectItem: {},
      tableData: [],
      page: 1,
      total: 0,
    };
  },
  props: {
    imgUrl: "",
    imgId: "",
  },
  watch: {
    imgUrl: {
      handler: function (val) {
        this.url = val;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() { },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.search()
    },
    close() {
      this.dialogPvVisible = false;
    },
    handleSelect(key, keyPath) {
      console.log('菜单切换', key, keyPath)
      this.page = 1
      this.activeIndex = key
      this.search()
    },
    search(type) {
      if (type == 'init') {
        this.page = 1
      }
      if (this.activeIndex == 1) {
        this.getIntegralList(1)
      } else if (this.activeIndex == 2) {
        this.getIntegralList(2)
      } else if (this.activeIndex == 3) {
        this.getInvitationList()
      }
    },
    // 获取邀请人员列表
    getInvitationList() {
      get_invitation_list(
        `?page=${this.page}&limit=10&user_id=${this.selectItem.user_center_id}`
      ).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      });
    },
    // 店员积分列表, score_type: '', // 积分类型，1=增加，2=减少
    getIntegralList(type) {
      get_integral_list(
        `?page=${this.page}&limit=10&user_id=${this.selectItem.user_center_id}&score_type=${type}`
      ).then(({ data: res }) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      });
    }

  },
};
</script>
<style lang="less" scoped>
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}

::v-deep .el-dialog__body {
  padding: 30px 70px;
}
</style>
